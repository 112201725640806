export const medicationRoutes = [
  {
    id: 'oral',
    name: 'Oral',
    description: 'Swallowed by mouth as a tablet, capsule, or liquid'
  },
  {
    id: 'nasal',
    name: 'Nasal',
    description: 'Given into the nose by spray or pump'
  },
  {
    id: 'ophthalmic',
    name: 'Ophthalmic (Eyes)',
    description: 'Given into the eye by drops, gel, or ointment'
  },
  {
    id: 'inhalation',
    name: 'Inhalation',
    description: 'Breathed in through a tube or mask'
  },
  {
    id: 'rectal',
    name: 'Rectal',
    description: 'Inserted into the rectum'
  },
  {
    id: 'vaginal',
    name: 'Vaginal',
    description: 'Inserted into the vagina'
  },
  {
    id: 'sublingual',
    name: 'Sublingual (Under Tongue)',
    description: 'Held under the tongue'
  },
  {
    id: 'buccal',
    name: 'Buccal (Inside Cheek)',
    description: 'Held inside the cheek'
  },
  {
    id: 'topical',
    name: 'Topical (Skin)',
    description: 'Applied to the skin'
  },
  {
    id: 'transdermal',
    name: 'Transdermal',
    description: 'Given through a patch placed on the skin'
  },
  {
    id: 'otic',
    name: 'Otic (Ear)',
    description: 'Given by drops into the ear'
  },
  {
    id: 'intravenous',
    name: 'Intravenous (Vein Injection)',
    description: 'Injected into a vein or into an IV line'
  },
  {
    id: 'intramuscular',
    name: 'Intramuscular (Muscle Injection)',
    description: 'Injected into muscle with a syringe'
  },
  {
    id: 'subcutaneous',
    name: 'Subcutaneous',
    description: 'Injected under the skin'
  },
  {
    id: 'gastrointestinalTract',
    name: 'Gastrointestinal Tract',
    description: 'Administered through the gastrointestinal tract'
  },
  {
    id: 'other',
    name: 'Other',
    description: 'Another route not specified above'
  }
] as const;

export type MedicationRoute = (typeof medicationRoutes)[number];

export const medicationRouteIds = medicationRoutes.map(({ id }) => id);

export type MedicationRouteId = (typeof medicationRouteIds)[number];
