import { TLookup } from '../types/lookup.type';

export const pharmacyApiMessageStatuses = [
  {
    id: 'pending',
    name: 'Pending',
    description: 'The pharmacy message has not yet been accepted or rejected',
    filterOptions: {
      isVisible: true,
      position: 1
    }
  },
  {
    id: 'accepted',
    name: 'Accepted',
    description:
      'The pharmacy message has been accepted and the inventory item has been added to the medication',
    filterOptions: {
      isVisible: true,
      position: 2
    }
  },
  {
    id: 'rejected',
    name: 'Rejected',
    description:
      'The pharmacy message has been rejected and no inventory item was added',
    filterOptions: {
      isVisible: true,
      position: 3
    }
  }
] as const satisfies TLookup[];

export type PharmacyApiMessageStatus =
  (typeof pharmacyApiMessageStatuses)[number];

export const pharmacyApiMessageStatusIds = pharmacyApiMessageStatuses.map(
  ({ id }) => id
);

export type PharmacyApiMessageStatusId =
  (typeof pharmacyApiMessageStatusIds)[number];
