export const documentStatuses = [
  {
    id: 'new',
    name: 'Not Started',
    description: 'The document has not been started',
    icon: 'assets/icon/material/file_document.svg',
    class: 'bg-sky-200 hover:bg-sky-200 text-sky-800',
    color: 'doc-status-new'
  },
  {
    id: 'data',
    name: 'Pending Data',
    description: 'The document still needs to be filled out',
    icon: 'assets/icon/alcomy/file_document_database.svg',
    class: 'bg-violet-200 hover:bg-violet-200 text-violet-800',
    color: 'doc-status-data'
  },
  {
    id: 'review',
    name: 'Pending Review',
    description: 'The document has been filled out, but needs to be reviewed',
    icon: 'assets/icon/alcomy/file_document_eye.svg',
    class: 'bg-violet-200 hover:bg-violet-200 text-violet-800',
    color: 'doc-status-review'
  },
  {
    id: 'signature',
    name: 'Pending Signature',
    description: 'The document has been reviewed, but needs to be signed',
    icon: 'assets/icon/material/file_sign.svg',
    class: 'bg-violet-200 hover:bg-violet-200 text-violet-800',
    color: 'doc-status-signature'
  },
  {
    id: 'complete',
    name: 'Complete',
    description: 'The document is considered complete',
    icon: 'assets/icon/material/file_document_check.svg',
    class: 'bg-green-200 hover:bg-green-200 text-green-800',
    color: 'doc-status-complete'
  }
] as const;

export type DocumentStatus = (typeof documentStatuses)[number];

export const DocumentStatusIds = documentStatuses.map(({ id }) => id);

export type DocumentStatusId = (typeof DocumentStatusIds)[number];
