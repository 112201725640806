export const pharmacyApiMessageTypes = [
  {
    id: 'newRx',
    name: 'New Rx Fill'
  },
  {
    id: 'refill',
    name: 'Refill Order'
  }
] as const;

export type PharmacyApiMessageType = (typeof pharmacyApiMessageTypes)[number];

export const pharmacyApiMessageTypeIds = pharmacyApiMessageTypes.map(
  ({ id }) => id
);

export type PharmacyApiMessageTypeId =
  (typeof pharmacyApiMessageTypeIds)[number];
