export const facilityTypes = [
  {
    id: 'rcfe',
    name: 'RCFE'
  },
  {
    id: 'arf',
    name: 'ARF'
  },
  {
    id: 'groupHome',
    name: 'Group Home'
  }
] as const;

export const facilityTypeIds = facilityTypes.map(({ id }) => id);

export type FacilityTypeId = (typeof facilityTypeIds)[number];
