export const recordChangeStatuses = [
  {
    id: 'pending',
    name: 'Pending'
  },
  {
    id: 'accepted',
    name: 'Accepted'
  },
  {
    id: 'rejected',
    name: 'Rejected'
  }
] as const;

export const recordChangeStatusIds = recordChangeStatuses.map(({ id }) => id);

export type IRecordChangeStatusId = (typeof recordChangeStatusIds)[number];
