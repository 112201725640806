export const pharmacySoftwareVendors = [
  {
    id: 'digitalRx',
    name: 'Digital Rx'
  }
];

export const pharmacySoftwareVendorIds = pharmacySoftwareVendors.map(
  ({ id }) => id
);

export type PharmacySoftwareVendorId =
  (typeof pharmacySoftwareVendorIds)[number];
