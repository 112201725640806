import { TLookup } from '../types/lookup.type';

export const medicalEventTypes = [
  {
    id: 'hospital',
    name: 'Hospital Visit',
    filterOptions: {
      label: 'Hospital Visit',
      isVisible: true
    }
  },
  {
    id: 'emergency',
    name: 'Emergency Call',
    filterOptions: {
      label: 'Emergency Call',
      isVisible: true
    }
  },
  {
    id: 'procedure',
    name: 'Medical Procedure',
    filterOptions: {
      label: 'Medical Procedure',
      isVisible: true
    }
  },
  {
    id: 'accident',
    name: 'Accident',
    filterOptions: {
      label: 'Accident',
      isVisible: true
    }
  },
  {
    id: 'visit',
    name: 'Medical Visit',
    filterOptions: {
      label: 'Medical Visit',
      isVisible: true
    }
  }
] as const satisfies TLookup[];

export const medicalEventTypeIds = medicalEventTypes.map(({ id }) => id);

export type MedicalEventTypeId = (typeof medicalEventTypeIds)[number];
