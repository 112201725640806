export const recordChangeActions = [
  {
    id: 'create',
    name: 'Create'
  },
  {
    id: 'update',
    name: 'Update'
  }
] as const;

export const recordChangeActionIds = recordChangeActions.map(({ id }) => id);

export type RecordChangeActionId = (typeof recordChangeActionIds)[number];
