import { TLookup } from '../types/lookup.type';

export const activityTypes = [
  {
    id: 'activity',
    name: 'Activity',
    description: "An activity that's done within the facility's premises.",
    filterOptions: {
      isVisible: true,
      label: 'Activity'
    }
  },
  {
    id: 'outing',
    name: 'Outing',
    description: "An activity that done outside the facility's premises.",
    filterOptions: {
      isVisible: true,
      label: 'Outing'
    }
  }
] as const satisfies TLookup[];

export const activityTypeIds = activityTypes.map(({ id }) => id);

export type ActivityTypeId = (typeof activityTypeIds)[number];
