import { TLookup } from '../types/lookup.type';

export const medicationStatuses = [
  {
    id: 'active',
    name: 'Active',
    filterOptions: {
      isVisible: true,
      position: 1
    }
  },
  {
    id: 'hold',
    name: 'Hold',
    filterOptions: {
      isVisible: true,
      position: 2
    }
  },
  {
    id: 'dc',
    name: 'Discontinued',
    filterOptions: {
      isVisible: true,
      position: 3
    }
  }
] as const satisfies TLookup[];

export type MedicationStatus = (typeof medicationStatuses)[number];

export const medicationStatusIds = medicationStatuses.map(({ id }) => id);

export type MedicationStatusId = (typeof medicationStatusIds)[number];
